import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-navi"
import { Input } from "reactstrap"
import {
    CheckboxWithSpinner,
    ITableNavigationControl,
    SimpleTable,
    SpinnerContainer,
    StandardButtonWithSpinner,
    TCreditCardType,
    TableItemDetails,
    getCountryByISO,
    getFormattedNumber,
    hasValue,
    useSelector,
} from "swiipe.portal.shared"
import PlusSellIcon from "../../images/swFeatureServices/plussell-icon.png"
import PlusSellInbetweenIcon from "../../images/swFeatureServices/plussell-inbetween-icon.png"
import PlusSellVoidedIcon from "../../images/swFeatureServices/plussell-voided-icon.png"
import ReturnSellIcon from "../../images/swFeatureServices/returnsell-icon.png"
import WinbackIcon from "../../images/swFeatureServices/winback-icon.png"
import { getFormattedDate } from "../../services/dateTimeService"
import {
    getActionReportData,
    getIconFromPaymentType,
    getIconPaddingFromPaymentType,
    getPlatformOrderIdFormatted,
    getSubscriptionOrderTypeText,
    getTransactionStatusTranslation,
    getTransactionTypeTranslation,
    getTranslationForPaymentType,
    resolvePaymentTypeNameForOrder,
} from "../../services/orderService"
import { StoreState } from "../../store/StoreState"
import { orderSelectors } from "../../store/reducers/orderReducer"
import { partnerSelectors } from "../../store/reducers/partnerReducer"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { getInfoForOrderThunk, getOrderThunk, getPaymentOrderDetailsThunk } from "../../store/thunks/orderThunks"
import { updateTransactionIsOnlySucceededAtProvider, voidThunk } from "../../store/thunks/transactionThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { Order } from "../../type/Order"
import { SucceededAtProviderOverride } from "../../type/Transaction"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import { OrderDetailsDto } from "../../type/orderDetailsDto"
import { PaymentOrderDetails } from "../../type/paymentOrderDetails"
import CreditCardTypeLogo from "../form/paymentmethods/CreditCardTypeLogo"
import ToolTipContainer from "../text/ToolTipContainer"
import "./SingleOrderReview.scss"

interface ISingleOrderReviewProps {
    orderId: string
    selectedWebshopId: string
    merchantDetails: MixedMerchantDetails
    onGoBack: () => void
    onShowAnotherOrder: (orderId?: string) => void
    navigationControl: ITableNavigationControl<Order>
}

export const SingleOrderReview = ({
    orderId,
    selectedWebshopId,
    merchantDetails,
    onGoBack,
    onShowAnotherOrder,
    navigationControl,
}: ISingleOrderReviewProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const order = useSelector<StoreState, Order | undefined>((state) => orderSelectors.getOrder(state, orderId))

    const paymentOrderDetails = useSelector<StoreState, PaymentOrderDetails | undefined>((state) =>
        orderSelectors.getPaymentOrderDetails(state, orderId)
    )

    const orderDetails = useSelector<StoreState, OrderDetailsDto | undefined>((state) =>
        orderSelectors.getOrderDetails(state, orderId)
    )

    const hasMasterPartner = useSelector(partnerSelectors.partnersContainMasterPartner)
    const getInternalOptionalBooleanValue = (value?: boolean) => (value === undefined ? "Not set" : value ? "Yes" : "No")

    const OrderLink = (props: { text: string; orderId?: string; platformOrderId?: string }) => {
        return (
            <span>
                {props.text}{" "}
                <Link href={"/orders?orderId=" + props.orderId} onClick={() => onShowAnotherOrder(props.orderId)}>
                    {props.platformOrderId}
                </Link>
            </span>
        )
    }

    const webshopPlatform = merchantDetails.webshops.find((webshop) => webshop.webshopId === selectedWebshopId)?.platform

    useEffect(() => {
        if (!order) {
            return
        }

        dispatch(getPaymentOrderDetailsThunk(selectedWebshopId, orderId, true))
        dispatch(getInfoForOrderThunk(selectedWebshopId, orderId, true))
    }, [order])

    return (
        <TableItemDetails
            className="single-order-container"
            onGoBack={onGoBack}
            backText={t("orderReview.goBack")}
            item={order}
            navigationControl={navigationControl}
            getNavigationTitle={(order, isPrevious) =>
                isPrevious
                    ? `${t("orderReview.previousOrder")} (${order.platformOrderId})`
                    : `${t("orderReview.nextOrder")} (${order.platformOrderId})`
            }
            getTopLeftConfig={(order) => ({
                className: "payment-info",
                content: (
                    <>
                        <div className="method">
                            <span>{t("orderReview.method")}</span>
                            <span>
                                <img
                                    className={order.paymentType === "CreditCard" ? "credit-card" : ""}
                                    src={getIconFromPaymentType(order.paymentType)}
                                    style={{ padding: getIconPaddingFromPaymentType(order.paymentType, order.paymentSubType) }}
                                ></img>
                                {resolvePaymentTypeNameForOrder(order)}
                            </span>
                        </div>
                        <hr />
                        {order.paymentInfo && (
                            <div>
                                <span>{getTranslationForPaymentType(order)}</span>
                                <span>{order.paymentInfo}</span>
                            </div>
                        )}
                        {order.paymentSubType && (
                            <div className="card-type">
                                <span>{t("orderReview.cardType")}:</span>
                                <span>
                                    <CreditCardTypeLogo paymentSubType={order.paymentSubType as TCreditCardType} onDefaultEmpty />
                                    {order.paymentSubType}
                                </span>
                            </div>
                        )}
                        {order.acquirer && (
                            <div>
                                <span>{t("orderReview.acquirer")}:</span>
                                <span>{order.acquirer}</span>
                            </div>
                        )}
                        {(order.paymentInfo || order.paymentSubType || order.acquirer) && <hr />}
                        <span className="report">
                            {t("orderReview.seeSuspicious")}{" "}
                            <span
                                onClick={() => {
                                    dispatch(
                                        addModalThunk({
                                            type: "reportFraud",
                                            title: t("transactions.reportFraudModal.title"),
                                            reportData: {
                                                webshopId: selectedWebshopId,
                                                platformOrderId: order.platformOrderId,
                                                orderId: order.orderId,
                                                swMerchantId: merchantDetails.swMerchant.swMerchantId,
                                                orderDate: getFormattedDate(order.created, "dots-only-date", true),
                                                currency: order.currency,
                                                amount: getFormattedNumber(order.amount, { numberFormat: "forceTwoDecimals" }),
                                            },
                                        })
                                    )
                                }}
                            >
                                {t("orderReview.reportFraud")}
                            </span>
                        </span>
                    </>
                ),
            })}
            getTopRightConfig={(order) => ({
                className: "order-info",
                mainRow: {
                    title: webshopPlatform === "prestashop" ? t("transactions.cartid") : t("transactions.orderid"),
                    value: order.platformOrderId,
                    extraContent: order?.paymentLinkUrl && (
                        <div className="payment-link-notice">{t("orderReview.createdAsPaymentLink")}</div>
                    ),
                },
                rows: [
                    (order?.plusSellChildOrderId || order?.plusSellParentOrderId) && {
                        type: "custom",
                        className: "webshopFeature-info",
                        content: (
                            <>
                                <span>
                                    {!order.plusSellParentOrderId && order.plusSellChildOrderId ? (
                                        <img src={PlusSellVoidedIcon}></img>
                                    ) : undefined}
                                    {order.plusSellParentOrderId && !order.plusSellChildOrderId ? (
                                        <img src={PlusSellIcon}></img>
                                    ) : undefined}
                                    {order.plusSellParentOrderId && order.plusSellChildOrderId ? (
                                        <img src={PlusSellInbetweenIcon}></img>
                                    ) : undefined}
                                    {!!order.plusSellParentOrderId && t("serviceNames.plusSell").toUpperCase()}
                                </span>

                                <div className="plussell-ids">
                                    {order?.plusSellChildOrderId && (
                                        <OrderLink
                                            text={
                                                order?.plusSellChildOrderId && order?.plusSellParentOrderId
                                                    ? t("transactions.plusSell.childInbetweenOrderId")
                                                    : t("transactions.plusSell.childOrderId")
                                            }
                                            orderId={order.plusSellChildOrderId}
                                            platformOrderId={paymentOrderDetails?.plusSellChildPlatformOrderId}
                                        />
                                    )}
                                    {order?.plusSellParentOrderId && (
                                        <OrderLink
                                            text={t("transactions.plusSell.parentOrderId")}
                                            orderId={order.plusSellParentOrderId}
                                            platformOrderId={paymentOrderDetails?.plusSellParentPlatformOrderId}
                                        />
                                    )}
                                </div>
                            </>
                        ),
                    },
                    (order?.returnSellChildOrderId || order?.returnSellParentOrderId) && {
                        type: "custom",
                        className: "webshopFeature-info",
                        content: (
                            <>
                                {order?.returnSellParentOrderId && (
                                    <span>
                                        <img src={ReturnSellIcon}></img>
                                        {t("serviceNames.returnSell").toUpperCase()}
                                    </span>
                                )}

                                <div className="returnsell-ids">
                                    {order?.returnSellChildOrderId && (
                                        <OrderLink
                                            text={t("transactions.returnSell.childOrderId")}
                                            orderId={order.returnSellChildOrderId}
                                            platformOrderId={paymentOrderDetails?.returnSellChildPlatformOrderId}
                                        />
                                    )}
                                    {order?.returnSellParentOrderId && (
                                        <OrderLink
                                            text={t("transactions.returnSell.parentOrderId")}
                                            orderId={order.returnSellParentOrderId}
                                            platformOrderId={paymentOrderDetails?.returnSellParentPlatformOrderId}
                                        />
                                    )}
                                </div>
                            </>
                        ),
                    },
                    paymentOrderDetails?.winbackPlatformOrderId &&
                        order.winbackOriginalOrderId && {
                            type: "custom",
                            className: "webshopFeature-info",
                            content: (
                                <>
                                    <span>
                                        <img src={WinbackIcon}></img> {t("serviceNames.winback").toUpperCase()}
                                    </span>
                                    {paymentOrderDetails?.winbackPlatformOrderId !== order?.platformOrderId && (
                                        <span>
                                            {webshopPlatform === "prestashop"
                                                ? t("transactions.winback.originalCartId")
                                                : t("transactions.winback.originalOrderId")}{" "}
                                            <Link href={"/orders?orderId=" + order?.winbackOriginalOrderId}>
                                                {paymentOrderDetails?.winbackPlatformOrderId}
                                            </Link>
                                        </span>
                                    )}
                                </>
                            ),
                        },
                    paymentOrderDetails?.subscriptionPlatformOrderId &&
                        order?.subscriptionOrderId && {
                            type: "custom",
                            className: "webshopFeature-info",
                            content: (
                                <>
                                    <span>
                                        <CreditCardTypeLogo
                                            paymentSubType={order?.paymentSubType as TCreditCardType}
                                            subscriptionType={order.subscriptionOrderType}
                                        />{" "}
                                        {order.subscriptionOrderType &&
                                            getSubscriptionOrderTypeText(order.subscriptionOrderType, true)}
                                    </span>
                                    {order.subscriptionOrderId !== order.orderId && (
                                        <span>
                                            {t("transactions.subscriptions.originalOrderId")}{" "}
                                            <Link href={"/orders?orderId=" + order.subscriptionOrderId}>
                                                {paymentOrderDetails.subscriptionPlatformOrderId}
                                            </Link>
                                        </span>
                                    )}
                                </>
                            ),
                        },
                    { type: "titleValue", title: t("transactions.created"), value: getFormattedDate(order.created, "slashes") },
                    { type: "titleValue", title: t("transactions.currency"), value: order.currency },
                    {
                        type: "titleValue",
                        title: t("transactions.amount"),
                        value: getFormattedNumber(order.amount, { numberFormat: "forceTwoDecimals" }),
                    },
                    order?.paymentLinkUrl && {
                        type: "custom",
                        className: "payment-link-input-container",
                        content: (
                            <>
                                <span>{t("orderReview.paymentLink")}</span>
                                <Input readOnly defaultValue={order.paymentLinkUrl} />
                            </>
                        ),
                    },
                    {
                        type: "custom",
                        className: "actions-container",
                        content: (
                            <>
                                {order.actions && order.actions.canCapture && (
                                    <StandardButtonWithSpinner
                                        isSmall
                                        className="capture-button"
                                        onClick={async () => {
                                            dispatch(
                                                addModalThunk({
                                                    type: "captureRefund",
                                                    minAmount: order.actions!.canPartialCapture
                                                        ? 0.01
                                                        : order.actions!.captureAmountLeft,
                                                    maxAmount: order.actions!.captureAmountLeft,
                                                    isRefund: false,
                                                    orderId: order.orderId,
                                                    webshopId: selectedWebshopId,
                                                    currency: order.currency,
                                                    paymentType: order.paymentType,
                                                    reportData: getActionReportData(
                                                        order,
                                                        selectedWebshopId,
                                                        merchantDetails.swMerchant.swMerchantId
                                                    ),
                                                    onUpdate: async () => {
                                                        await dispatch(getOrderThunk(selectedWebshopId, order.orderId, true))
                                                        await dispatch(
                                                            getPaymentOrderDetailsThunk(selectedWebshopId, order.orderId, true)
                                                        )
                                                    },
                                                })
                                            )
                                        }}
                                    >
                                        {t("transactions.action.capture")}
                                    </StandardButtonWithSpinner>
                                )}
                                {order.actions && order.actions.canCancel && (
                                    <StandardButtonWithSpinner
                                        invertedBlue
                                        isSmall
                                        className="cancel-button"
                                        onClick={async () => {
                                            const modalAnswer = await dispatch(
                                                addModalThunk({
                                                    type: "cancelTransaction",
                                                })
                                            )

                                            if (modalAnswer.type === "accepted") {
                                                const reportData = getActionReportData(
                                                    order,
                                                    selectedWebshopId,
                                                    merchantDetails.swMerchant.swMerchantId
                                                )
                                                await dispatch(
                                                    voidThunk(
                                                        selectedWebshopId,
                                                        order.orderId,
                                                        getPlatformOrderIdFormatted(order.platformOrderId),
                                                        reportData
                                                    )
                                                )

                                                await dispatch(getOrderThunk(selectedWebshopId, order.orderId, true))
                                                await dispatch(
                                                    getPaymentOrderDetailsThunk(selectedWebshopId, order.orderId, true)
                                                )
                                            }
                                        }}
                                    >
                                        {t("transactions.action.cancel")}
                                    </StandardButtonWithSpinner>
                                )}
                                {order.actions && order.actions.canRefund && (
                                    <StandardButtonWithSpinner
                                        className="refund-button"
                                        isSmall
                                        onClick={async () => {
                                            dispatch(
                                                addModalThunk({
                                                    type: "captureRefund",
                                                    minAmount: order.actions!.canPartialRefund
                                                        ? 0.01
                                                        : order.actions!.refundAmountLeft,
                                                    maxAmount: order.actions!.refundAmountLeft,
                                                    isRefund: true,
                                                    orderId: order.orderId,
                                                    webshopId: selectedWebshopId,
                                                    currency: order.currency,
                                                    paymentType: order.paymentType,
                                                    reportData: getActionReportData(
                                                        order,
                                                        selectedWebshopId,
                                                        merchantDetails.swMerchant.swMerchantId
                                                    ),
                                                    onUpdate: async () => {
                                                        await dispatch(getOrderThunk(selectedWebshopId, order.orderId, true))
                                                        await dispatch(
                                                            getPaymentOrderDetailsThunk(selectedWebshopId, order.orderId, true)
                                                        )
                                                    },
                                                })
                                            )
                                        }}
                                    >
                                        {t("transactions.action.refund")}
                                    </StandardButtonWithSpinner>
                                )}
                            </>
                        ),
                    },
                ],
            })}
            renderBottom={(order) => (
                <>
                    <SimpleTable
                        clasName="transactions"
                        items={paymentOrderDetails?.transactions}
                        title={t("transactions.title")}
                        renderHeaderCells={() =>
                            [
                                { content: t("transactions.date"), className: "date" },
                                { content: t("transactions.type") },
                                { content: t("transactions.status") },
                                { content: t("transactions.amount") },
                                { content: t("transactions.transactionId"), className: "tx-id" },
                                { content: t("transactions.notes"), className: "notes" },
                                hasMasterPartner ? { content: "Override Status:" } : undefined,
                            ].filter(hasValue)
                        }
                        renderRowCells={(tx) =>
                            [
                                { content: getFormattedDate(tx.created, "slashes") },
                                { content: getTransactionTypeTranslation(tx.transactionType) },
                                { content: getTransactionStatusTranslation(tx.transactionStatus) },
                                { content: getFormattedNumber(tx.amount, { numberFormat: "forceTwoDecimals" }) },
                                {
                                    content: (
                                        <ToolTipContainer
                                            centerY
                                            noWrap
                                            text={tx.transactionReference}
                                            disable={tx.transactionReference.length < 9}
                                        >
                                            {tx.transactionReference.length > 8
                                                ? ".." +
                                                  tx.transactionReference.substring(
                                                      tx.transactionReference.length - 8,
                                                      tx.transactionReference.length
                                                  )
                                                : tx.transactionReference}
                                        </ToolTipContainer>
                                    ),
                                },
                                {
                                    content: (
                                        <>
                                            {tx.transactionReason && t(`paymentReasons.${tx.transactionReason}`) + " | "}
                                            {tx.errorMessage}
                                            {(tx.notes ||
                                                tx.errorDetails ||
                                                tx.paymentCallbackDetails ||
                                                tx.swPaymentErrorCategory) && (
                                                <button
                                                    className="error-details-btn"
                                                    onClick={() => {
                                                        dispatch(
                                                            addModalThunk({
                                                                type: "transactionTechnicalDetails",
                                                                transaction: tx,
                                                            })
                                                        )
                                                    }}
                                                >
                                                    {t("transactions.seeMore")}{" "}
                                                </button>
                                            )}
                                        </>
                                    ),
                                },
                                // prettier-ignore
                                hasMasterPartner ? {
                                    content: (
                                        <>
                                            {
                                                (
                                                    order.actions.canRefund && tx.isOnlySucceededAtProvider !== SucceededAtProviderOverride.AlreadyUsed && tx.transactionType === "Refund" && tx.transactionStatus === "Failure"
                                                ) && (
                                                    <CheckboxWithSpinner
                                                        name="succeeded-at-provider"
                                                        checked={tx.isOnlySucceededAtProvider === SucceededAtProviderOverride.Enabled}
                                                        handleChange={async (checked: boolean) => {
                                                            await dispatch(
                                                                updateTransactionIsOnlySucceededAtProvider(
                                                                    selectedWebshopId,
                                                                    tx,
                                                                    checked
                                                                )
                                                            )
                                                        }}
                                                        label={"Allow Override"}
                                                        hintText="If you're confident this refund was successful at the payment provider but didn't register correctly in Swiipe,
                                                        you can allow an override. This will allow the merchant to retry the refund (ensuring the amount and currency match),
                                                        without notifying the payment provider, so it registers correctly in Swiipe."
                                                    />
                                                )
                                            }
                                            {
                                                (
                                                        tx.isOnlySucceededAtProvider === SucceededAtProviderOverride.AlreadyUsed
                                                ) && (
                                                    <>
                                                        <span>Overridden:<br/> {getFormattedDate(tx.onlySucceededAtProviderUsedTime ?? "", "slashes")}</span>
                                                    </>
                                                )
                                            }
                                        </>
                                    ),
                                } : undefined,
                            ].filter(hasValue)
                        }
                    />
                    <div className="customer-data">
                        <p className="heading">{t("orderReview.customer")}</p>
                        <SpinnerContainer showSpinner={!orderDetails}>
                            {orderDetails && (orderDetails?.user || orderDetails?.billingAddress) && (
                                <div className="order-details">
                                    {orderDetails.billingAddress && (
                                        <div>
                                            <p className="mb-2">
                                                <b>{t("orderReview.billing")}:</b>
                                            </p>
                                            <span>{orderDetails.billingAddress.fullName}</span>
                                            <span>{orderDetails.billingAddress.address}</span>
                                            <span>
                                                {orderDetails.billingAddress.postCode}, {orderDetails.billingAddress.city}
                                            </span>
                                            <span>{getCountryByISO(orderDetails.billingAddress.country || "")}</span>
                                            <span>{orderDetails.billingAddress.companyName}</span>
                                        </div>
                                    )}

                                    {orderDetails.deliveryLocation && (
                                        <div>
                                            <p className="mb-2">
                                                <b>{t("orderReview.shipping")}:</b>
                                            </p>
                                            <span>
                                                {orderDetails.deliveryLocation.deliveryProvider}{" "}
                                                {orderDetails.deliveryLocation.deliveryType}
                                            </span>
                                            <span>{orderDetails.deliveryLocation.fullName}</span>
                                            <span>{orderDetails.deliveryLocation.address}</span>
                                            <span>
                                                {orderDetails.deliveryLocation.postCode}, {orderDetails.deliveryLocation.city}
                                            </span>
                                            <span>{getCountryByISO(orderDetails.deliveryLocation.country || "")}</span>
                                            <span>{orderDetails.deliveryLocation.companyName}</span>
                                        </div>
                                    )}

                                    {orderDetails?.user && (
                                        <div>
                                            <p className="mb-2">
                                                <b>{t("orderReview.user")}:</b>
                                            </p>
                                            <span>{orderDetails.user.email}</span>
                                            <span>{orderDetails.user.phone}</span>
                                            {hasMasterPartner && (
                                                <span>
                                                    Logged in Swiipe:{" "}
                                                    {getInternalOptionalBooleanValue(orderDetails.isLoggedInSwiipe)}
                                                </span>
                                            )}
                                            {hasMasterPartner && (
                                                <span>
                                                    Logged in Platform:{" "}
                                                    {getInternalOptionalBooleanValue(orderDetails.isLoggedInPlatform)}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </SpinnerContainer>
                    </div>
                </>
            )}
        />
    )
}
