import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import {
    BaseModal,
    IModalBase,
    IModalControlProps,
    ModalFooter,
    AccordionWithChildren,
    useSelector,
    CleanHtml,
} from "swiipe.portal.shared"
import { OrderTransaction } from "../../type/Transaction"
import "./ModalTransactionTechnicalDetails.scss"
import { partnerSelectors } from "../../store/reducers/partnerReducer"

export interface IModalTransactionDetails extends IModalBase {
    type: "transactionTechnicalDetails"
    transaction: OrderTransaction
}

const ModalTransactionDetails = ({ transaction, callback, ...otherProps }: IModalTransactionDetails & IModalControlProps) => {
    const { t } = useTranslation()
    const isMasterPartner = useSelector(partnerSelectors.partnersContainMasterPartner)
    const technicalDetailsHtml = prepareTechnicalDetails(transaction)

    return (
        <BaseModal
            {...otherProps}
            modalClassName="info-modal"
            callback={callback}
            centered
            title={t("transactions.technicalDetailsModel.title")}
        >
            <ModalBody>
                <section className="technical-details">
                    {getStatusText(transaction)}
                    {transaction.swPaymentErrorCategory && <CleanHtml element="div" html={transaction.swPaymentErrorCategory} />}
                    {technicalDetailsHtml && <CleanHtml element="div" html={technicalDetailsHtml} />}
                    {transaction.paymentCallbackDetails && (
                        <>
                            <hr />
                            <AccordionWithChildren header="Show callback details" isInitiallyExpanded={isMasterPartner}>
                                <CleanHtml element="div" html={transaction.paymentCallbackDetails} />
                            </AccordionWithChildren>
                        </>
                    )}
                </section>
            </ModalBody>
            <ModalFooter rightButton={{ onClick: async () => callback("accepted"), text: t("common.close") }} />
        </BaseModal>
    )
}

const prepareTechnicalDetails = (transaction: OrderTransaction): string | null => {
    const detailsParts: string[] = [transaction.notes, transaction.errorDetails]
        .map((part) => part?.trim() ?? "")
        .filter((part) => !!part)

    if (detailsParts.length === 0) {
        return null
    }

    const detailsSection = "<hr />" + detailsParts.join("<br/><br/>")
    return detailsSection
}

const getStatusText = (transaction: OrderTransaction): string => {
    const { t } = useTranslation()
    let statusText = ""

    switch (transaction.transactionStatus) {
        case "Success":
            statusText = t("transactions.technicalDetailsModel.status.wasSuccessful")
            break

        case "Failure":
            statusText = t("transactions.technicalDetailsModel.status.hasFailed")
            break

        case "Pending":
            statusText = t("transactions.technicalDetailsModel.status.isPending")
            break

        case "Cancelled":
            statusText = t("transactions.technicalDetailsModel.status.isCancelled")
            break
    }

    return `${t("transactions.technicalDetailsModel.transactionType." + transaction.transactionType)} ${statusText}.`
}

export default ModalTransactionDetails
